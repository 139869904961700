import interactive from './interactive.js';
import viewRouting from './viewRouting.js';

document.addEventListener('DOMContentLoaded', () => {
  console.log('hej');
  interactive.init();
  viewRouting.init();
  initNavigationDevice();
  initAbout();
  startTime();
});

// Starts the function to display the current time
const startTime = () => {
  const today = new Date();
  let hours = today.getHours();
  let minutes = today.getMinutes();
  let seconds = today.getSeconds();
  minutes = checkTime(minutes);
  seconds = checkTime(seconds);
  document.getElementById('clock').innerHTML = `${hours}:${minutes}:${seconds}`;
  setTimeout(startTime, 1000); // Updates the time every second
};

// Adds a zero in front of numbers less than 10
const checkTime = i => {
  if (i < 10) {
    i = `0${i}`;
  }
  return i;
};

const screen = document.querySelector('.device .screen');
const wrapper = screen.querySelector('.dvd-logo');
const image = screen.querySelector('.dvd-logo img');
const transformX = screen.querySelector('.transfrom-x-wrapper');
const transformY = screen.querySelector('.transfrom-y-wrapper');
const redLight = document.body.querySelector('.device .light.red');
const greenLight = document.body.querySelector('.device .light.green');

const setBlueScreenSize = () => {
  transformX.classList.remove('dvd-animation-x');
  transformY.classList.remove('dvd-animation-y');

  transformX.style.setProperty('--blue-screen-width', `${(screen.clientWidth - image.clientWidth) * -1}px`);
  transformY.style.setProperty('--blue-screen-height', `${screen.clientHeight - image.clientHeight}px`);

  transformX.classList.add('dvd-animation-x');
  transformY.classList.add('dvd-animation-y');
};

function addClassWhenVisible(element, target, className) {
  const options = {
    root: null, // Use the viewport as the root
    rootMargin: '0px', // No margin
    threshold: 1.0, // Fully visible
  };

  const observer = new IntersectionObserver(entries => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        target.classList.add(className);
        console.log('ssss');
        observer.unobserve(element); // Stop observing once the element is fully visible
      }
    });
  }, options);

  observer.observe(element);
}

const initAbout = () => {
  const card = document.body.querySelector('.about .card');
  const mini = card.querySelector('.minifigures');
  card.addEventListener('mouseenter', e => {
    mini.classList.add('show-names');
  });

  // Usage example:

  addClassWhenVisible(card, mini, 'show-names'); // Replace 'visible-class' with the class you want to add
};

const initNavigationDevice = () => {
  setBlueScreenSize();
  document.addEventListener('resize', setBlueScreenSize());

  const navigationLinks = document.body.querySelectorAll('.navigation nav a');
  navigationLinks.forEach(link => {
    link.addEventListener('mouseenter', e => {
      redLight.classList.remove('blink');
      greenLight.classList.remove('blink');
      greenLight.classList.add('blink');
    });
    link.addEventListener('mouseleave', e => {
      redLight.classList.remove('blink');
      greenLight.classList.remove('blink');
    });
  });
};

window.onload = function () {
  setTimeout(() => {
    wrapper.style.opacity = 1;
    redLight.classList.remove('blink');
  }, 1000);
};
