import VanillaTilt from 'vanilla-tilt';

function rand(min, max) {
  return Math.floor(min + Math.random() * (max - min));
}

function isTouchDevice() {
  return 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
}


const init = () => {

  const headerCard = document.querySelector('header .card')

  headerCard.addEventListener('click', () =>{
    const hue = rand(0, 360)
    console.log('object');
    document.documentElement.style.setProperty(
    '--color-main',
    `hsl(${hue}, 98%, 40%)`
  );
  })

  if(isTouchDevice()){
   return
  }

  VanillaTilt.init(headerCard, {
    max: isTouchDevice() ? 0 : 4,
    speed: 800,
    perspective: 1000,
    glare: true,
    "max-glare": .4,  
    reverse: true,

    "mouse-event-element": document.querySelector('header')
  });
  
  VanillaTilt.init(document.querySelector('.navigation'), {
    max: 2,
    speed: 1200,
    perspective: 1000,
    glare: true,
    "max-glare": .1,  
    reverse: true,
  });
  VanillaTilt.init(document.querySelector('main .navigation-inner'), {
    max: 2,
    speed: 1200,
    perspective: 1000,
    glare: true,
    "max-glare": .1,  
    reverse: true,
  });
  VanillaTilt.init(document.querySelectorAll('.table .package'), {
    max: isTouchDevice() ? 0 : 4,
    speed: 800,
    perspective: 1000,
    glare: true,
    "max-glare": .3,  
  });
};



export default { init };
